/* eslint-disable react-hooks/exhaustive-deps */
import i18n, { TOptions } from "i18next";
import { useCallback, useEffect, useState } from "react";

type ResourceProp<T> = {
  "pt-BR": T;
  "en-US": T;
  "es-ES": T;
};

type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

type Path<T> = PathImpl<T, keyof T> | keyof T;

function makeid(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  const handleCryptoRandom: any = () => {
    const crypto = window.crypto;
    const cryptoArray = new Uint32Array(1);
    return `0.${crypto.getRandomValues(cryptoArray)[0]}`;
  };
  for (let i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(handleCryptoRandom() * charactersLength)
    );
  }
  return result;
}

const useTranslation = <T, P extends Path<T>>(resource: ResourceProp<T>) => {
  const [namespace] = useState(makeid(20));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!i18n) return;
    for (let language in resource) {
      i18n?.addResourceBundle(
        language,
        namespace,
        resource[language],
        true,
        true
      );
    }
    setLoaded(true);
    return () => {
      for (let language in resource) {
        i18n?.removeResourceBundle(language, namespace);
      }
    };
  }, [i18n]);

  const t = useCallback(
    (key: P, options?: TOptions) => {
      if (!loaded) return key;
      const _defaultOptions = { lng: i18n.language, ns: namespace };
      if (options) {
        return i18n.t(`${key as String}`, { ..._defaultOptions, ...options });
      }
      return i18n.t(`${key as String}`, _defaultOptions);
    },
    [loaded]
  );

  return {
    t,
    loaded,
  };
};

export default useTranslation;
