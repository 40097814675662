import ButtonType1 from "../../Buttons/ButtonType1";
import google from "../../../assets/images/icons/google.svg";
import useTranslation from "../../../hooks/useTranslation";
import { GroupLoginI18n } from "../GroupLogin.i18n";
import { RecoverPassword } from "./RecoverPassword";
import Web3Login from "./Web3Login";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import { useState } from "react";

const Web2Login = ({
  isRegistering,
  isResetingPassword,
  setIsResetingPassword,
  isLoading = false,
  isLoadingGoogle = false,
  isLoadingWeb3 = false,
  email,
  handleLogin = () => {},
  handleLoginWeb3 = () => {},
  handleSocialLogin = () => {},
  onChangeEmail = () => {},
  onChangePassword = () => {},
}) => {
  const { t } = useTranslation(GroupLoginI18n);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {isResetingPassword ? (
        <RecoverPassword recoverEmail={email} t={t} />
      ) : (
        <form
          action=""
          onSubmit={e => {
            e.preventDefault();
            handleLogin();
          }}
          className="form-login-web2"
        >
          <div className="group">
            <div className="w-100 d-flex flex-column gap-4">
              <Web3Login
                handleLoginWeb3={handleLoginWeb3}
                isLoading={isLoadingWeb3}
              />
              <ButtonType1
                isLoading={isLoadingGoogle}
                icon={google}
                text={t("google")}
                variation="thirty"
                props={{
                  className: "btn-socials",
                  type: "button",
                  onClick: handleSocialLogin,
                }}
              />
            </div>
            <div className={"divider my-4"}></div>
            {/* {isRegistering && (
              <div className="d-flex gap-3" style={{ marginBottom: 20 }}>
                <div>
                  <label htmlFor="email">{t("firstName")}</label>
                  <input
                    type="text"
                    name="name"
                    required
                    onChange={onChangeEmail}
                    placeholder={t("placeholderFirstName")}
                  />
                </div>
                <div>
                  <label htmlFor="email">{t("lastName")}</label>
                  <input
                    type="text"
                    name="name"
                    required
                    onChange={onChangeEmail}
                    placeholder={t("placeholderLastName")}
                  />
                </div>
              </div>
            )} */}
            <label htmlFor="email">{t("email")}</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              onChange={onChangeEmail}
              placeholder={t("placeholderEmail")}
            />
          </div>
          <div className="group">
            <div className="d-flex align-items-center justify-content-between">
              <label htmlFor="password">{t("password")}</label>
              {!isRegistering && (
                <button
                  className=" text-decoration-underline"
                  style={{ fontWeight: 500 }}
                  type="button"
                  onClick={() => setIsResetingPassword(true)}
                >
                  {t("forgotPassword")}
                </button>
              )}
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                background: COLORS.gray100,
                borderRadius: 8,
                paddingRight: 14,
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                onChange={onChangePassword}
                name="password"
                id="password"
                required
                placeholder={t("placeholderPassword")}
              />
              {showPassword ? (
                <IconEye
                  size={20}
                  stroke={1.5}
                  className="cursor-pointer"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <IconEyeOff
                  size={20}
                  stroke={1.5}
                  className="cursor-pointer"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
          </div>
          <ButtonType1
            text={isRegistering ? t("createAccount") : t("signIn")}
            isLoading={isLoading}
            variation="primary"
            props={{
              className: "btn-main-form",
              type: "submit",
            }}
          />
        </form>
      )}
    </>
  );
};

export default Web2Login;
