import COLORS from "../../../utils/constants/colors";
import imgMenuWallet from "../../../assets/images/icons-new-layout/menu-web3.png";
import imgMenuSelectAssistant from "../../../assets/images/icons-new-layout/menu-selelct-assistant.webp";
import previewSite from "../../../assets/images/icons-new-layout/preview-chat-landing-page.webp";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import groupDots from "../../../assets/images/icons-new-layout/groupDotsLigth.svg";
import useTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";

const SectionOneLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = useTranslation(LandingPageI18n);

  const lg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <section
      style={{
        position: "relative",
        background: "linear-gradient(180deg, #FFFFFF 0%, #F2F4F7 100%)",
        padding: lg ? "0 16px 80px 16px" : "0 120px 120px 120px",
      }}
    >
      {lg ? (
        ""
      ) : (
        <>
          <img
            src={groupDots}
            alt=""
            style={{
              position: "absolute",
              bottom: 190,
              left: 60,
              zIndex: 0,
            }}
          />
          <img
            src={groupDots}
            alt=""
            style={{
              position: "absolute",
              top: 474,
              right: 92,
              zIndex: 0,
            }}
          />
        </>
      )}
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: lg ? 40 : 80, gap: lg ? 40 : 56 }}
      >
        <TitleLandingPage
          darkMode
          largeFont={true}
          gap={24}
          alignItems={"center"}
          textAlign={"center"}
          widthTitle={500}
          justifyContent={"center"}
          headerText={t("sectionOne.badge")}
          headerWidth={100}
          border={`1px solid ${COLORS.gray200}`}
          borderRadius={100}
          title={t("sectionOne.title")}
          description={t("sectionOne.description")}
        />
        <div className="position-relative w-100 d-flex justify-content-center" style={{ maxWidth: 1000 }}>
          <img src={previewSite} alt="" style={{ borderRadius: lg ? 6 : 16, width: "100%" }} />
          {lg ? (
            ""
          ) : (
            <>
              <img
                src={imgMenuWallet}
                alt=""
                className="position-absolute"
                style={{ left: -50, top: 228 }}
              />
              <img
                src={imgMenuSelectAssistant}
                alt=""
                className="position-absolute"
                style={{ right: -120, bottom: 100, width: 205, height: 248 }}
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionOneLandingPage;
