/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";
import { getUserPaymentHistory } from "../../../../api/creditPayments";
import { formatPriceInUSD } from "../../../../utils/helpers";
import { formateDate } from "../../../../utils/dateutils";
import COLORS from "../../../../utils/constants/colors";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { IconExternalLink, IconLoader2 } from "@tabler/icons-react";
import { PageSettingsI18n } from "../PageSettings.i18n";
import useTranslation from "../../../../hooks/useTranslation";
import { showRealoadNotification } from "../../../../services/notifications";
import { useEffect } from "react";

export function PaymentHistory() {
  let showError = false;
  const { data: payments, isLoading: isLoadingPayments, error } = useQuery(
    QUERY_KEYS.USER_PAYMENTS,
    getUserPaymentHistory,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { t } = useTranslation(PageSettingsI18n);

  const paymentsArray = Array.isArray(payments)
    ? payments
    : payments?.data || [];

  const filteredPayments = paymentsArray.filter(
    payment => !(payment.method === "pix" && payment.status === "pending")
  );

  const sortedPayments = filteredPayments
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    useEffect(() => {
      showRealoadNotification((error ? error.message : null), showError);
      showError = true;
   }, [error]);

  return (
    <div className="mt-5 d-flex flex-column gap-2">
      <strong>{t("credits.invoice")}</strong>
      {isLoadingPayments ? (
        <div className="mt-3 d-flex align-items-center justify-content-center">
          <IconLoader2 className="spin" />
        </div>
      ) : (
        <>
          <div
            className="scrollable-container"
            style={{ maxHeight: "400px"}}
          >
            {sortedPayments &&
              sortedPayments.length > 0 &&
              sortedPayments.map(payment => (
                <div
                  key={payment.id}
                  className="history-payments"
                  style={{
                    border: `1px solid ${COLORS.gray300}`,
                    borderRadius: 10,
                    padding: "12px",
                    marginBottom: "16px",
                  }}
                >
                  <div className="d-flex flex-column flex-sm-row align-items-center gap-sm-3 gap-2">
                    <span>{formateDate(payment.updated_at)}</span>
                    <span>{formatPriceInUSD(Number(payment.amount))}</span>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minWidth: 90 }}
                  >
                    <span
                      className="text-center text-capitalize"
                      style={{
                        background:
                          payment.status === "completed"
                            ? "#5cb85c"
                            : payment.status === "pending"
                            ? COLORS.gray100
                            : "#f1404b",
                        color:
                          payment.status === "pending"
                            ? COLORS.gray900
                            : "#fff",
                        padding: "6px 10px",
                        borderRadius: 8,
                        maxWidth: 100,
                        minWidth: 90,
                      }}
                    >
                      {payment.status}
                    </span>
                  </div>

                  <div className="d-flex align-items-center gap-4 text-nowrap">
                    <span className="text-capitalize fw-bold">
                      {payment.method.replace("_", " ")}
                    </span>

                    {payment.method === "token" && (
                      <a
                        href={`https://basescan.org/tx/${payment.transaction_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="small fw-semibold d-flex align-items-center gap-1"
                      >
                        View transaction <IconExternalLink size={16} />
                      </a>
                    )}

                    {payment.method === "card" &&
                      payment.status === "pending" &&
                      payment.payment_url && (
                        <a
                          href={payment.payment_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="small fw-semibold d-flex align-items-center gap-1"
                        >
                          Continue Payment <IconExternalLink size={16} />
                        </a>
                      )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
