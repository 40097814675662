import { Link, useNavigate } from "react-router-dom";
import COLORS from "../../../utils/constants/colors";
import { AizzyLogo } from "../../AizzyLogo";
import ButtonType1 from "../../Buttons/ButtonType1";
import { IconX } from "@tabler/icons-react";
import { LandingPageI18n } from "./LandingPageTranslactions";
import useTranslation from "../../../hooks/useTranslation";
import SocialMideas from "../../../utils/constants/SocialMedia";

const MenuMobile = ({ openMenuMobile }) => {
  const navigate = useNavigate();

  const { t } = useTranslation(LandingPageI18n);
  const arrayNav = [
    {
      title: "About",
      link: "",
    },
    {
      title: "Capabilities",
      link: "",
    },
    {
      title: "Docs",
      link: "",
    },
    {
      title: "FAQ",
      link: "",
    },
  ];
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          zIndex: 888,
          backgroundColor: "#ffffff",
          opacity: 0.6,
        }}
      ></div>
      <div
        className="d-flex flex-column align-items-center p-3"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "calc(100vh - 200px)",
          zIndex: 999,
          backgroundColor: COLORS.white,
        }}
      >
        <div
          className="w-100 h-100 d-flex align-items-center flex-column gap-4 rounded-3"
          style={{
            borderBottom: `1px solid ${COLORS.gray100}`,
            boxShadow: `
             0px 0px 0px 4px rgba(242, 244, 247, 1),
                0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
            padding: "16px 16px 16px 24px",
          }}
        >
          <nav
            className="w-100 d-flex justify-content-between align-items-center"
            style={{ marginTop: 12, padding: "5px 9px 0 0" }}
          >
            <AizzyLogo width={83} height={24} />

            <div className="d-flex gap-2">
              <ButtonType1
                text={t("button")}
                props={{
                  onClick: () => navigate("/"),
                  desabled: false,
                  style: {
                    borderRadius: "8px",
                    width: 110,
                    height: 32,
                  },
                }}
              />
              <ButtonType1
                props={{
                  disabled: false,
                  onClick: openMenuMobile,
                  style: {
                    borderRadius: "10px",
                    background: COLORS.gray100,
                    width: 32,
                    height: 32,
                  },
                }}
                variation="secondary"
                text={<IconX size={16} stroke={1.5} />}
              />
            </div>
          </nav>
          <div></div>
          <div className="w-100">
            <ul className="w-100 d-flex flex-column gap-2">
              {arrayNav.map((item, index) => (
                <li
                  className="d-flex align-items-center"
                  key={index}
                  style={{ height: 44 }}
                >
                  <Link
                    href={item.link}
                    onClick={() => navigate(item.link)}
                    className="d-flex align-items-center gap-3"
                  >
                    <span style={{ color: COLORS.gray500 }}>{item.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="d-flex justify-content-between w-100 flex-grow-1 align-items-end"
            style={{ color: COLORS.gray400 }}
          >
            <span className="font-size-12">
              © 2024 Aizzy. All rights reserved.
            </span>
            <ul className="d-flex gap-2">
              {SocialMideas.map((social, index) => (
                <li key={index}>
                  <Link
                    to={social.link}
                    target="_blank"
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 6,
                      background: COLORS.gray100,
                    }}
                  >
                    <img
                      src={social.image}
                      alt={social.name}
                      width={16}
                      height={16}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuMobile;
