/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import AssistantsAizzyApp from "./AssistantsAizzyApp";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../libs/react-query";
import { getFreeCommunityAssistants } from "../../../api/assistants";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getCurrentUserLanguage } from "../../../services/userHelper";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import useTranslation from "../../../hooks/useTranslation";
import { PageAssistantsI18n } from "./HeaderPageAssistants/HeaderPageAssistants.i18n";
import { showRealoadNotification } from "../../../services/notifications";

const AssistantsTopPicks = ({ listAssistants, renderAizzyTabProp }) => {
  let showError = false;
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const { t } = useTranslation(PageAssistantsI18n);

  const userLanguage = getCurrentUserLanguage();

  const { data, isLoading, error } = useQuery(
    [QUERY_KEYS.COMMUNITY_ASSISTANTS, page],
    () =>
      getFreeCommunityAssistants({
        pageNumber: page,
        language: userLanguage,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );

  const renderSkeletons = [...Array(6)].map((_, index) => (
    <Skeleton
      key={`skeleton-${index}`}
      baseColor={"#e4e4e7"}
      highlightColor={"#F1F1F1"}
      duration={1}
      height={132}
      borderRadius={16}
    />
  ));

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);

  return (
    <div className="d-flex flex-column gap-5 mt-3">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-2">
          <h3>{t("subTitles.trending")} 🔥</h3>
          <span>{t("subTitles.paragraphTrending")}</span>
        </div>
        <div className="grid-horizontals-assistants">
          {isLoading
            ? renderSkeletons
            : data && data?.assistants
              .slice(0, 6)
              .map((assistant, index) => (
                <CardAssistantsNewLayout
                  setOpenModalInfo={() => handleCardClick(assistant)}
                  key={index}
                  direction="horizontal"
                  image={assistant.profileImage}
                  title={assistant.name}
                  description={assistant.role}
                  icon={assistant.profileImage}
                  name={assistant.userName}
                  assistant={assistant}
                  onSelect={() =>
                    dispatch(
                      setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                    )
                  }
                />
              ))}
        </div>
      </div>
      <AssistantsAizzyApp
        listAssistants={listAssistants}
        renderAizzyTabProp={renderAizzyTabProp}
      />
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => {
          setOpenModalInfo(false);
        }}
        assistant={selectedAssistant}
      />
    </div>
  );
};

export default AssistantsTopPicks;
