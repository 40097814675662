/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ButtonType1 from "../../../Buttons/ButtonType1";
import ModalGlobal from "../../../Modals/ModalGlobal";
import { useUser } from "@supabase/auth-helpers-react";
import {
  getUserPaymentById,
  postPayment,
} from "../../../../api/creditPayments";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../services/notifications";
import { web3Service } from "../../../../services/web3/web3";
import { getAmountOfAIZPerUsdc } from "../../../../api/web3";
import { PAYMENT_METHODS } from "../../../../utils/constants/paymentMethods";
import { BLOCKCHAINS } from "../../../../services/web3/NetworkConnections";
import { QUERY_KEYS, queryClient } from "../../../../libs/react-query";
import { IconCopy, IconLoader2 } from "@tabler/icons-react";
import { formatPriceInUSD } from "../../../../utils/helpers";
import { toast } from "sonner";
import useTranslation from "../../../../hooks/useTranslation";
import { PageSettingsI18n } from "../PageSettings.i18n";
import { NotificationI18n } from "../../../Add-ons/Notifications.i18n";
import ModalPayment from "../../../Modals/ModalPayment";
import imgPix from "../../../../assets/images/icons-new-layout/pix.svg";
import COLORS from "../../../../utils/constants/colors";

export function PurchaseCredits({ credits }) {
  const [isOpenSelect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [valueAmount, setValueAmount] = useState(10);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qrcodeImage, setQRCodeImage] = useState(null);
  const [modalPix, setModalPix] = useState(false);
  const [pixCode, setPixCode] = useState("");
  const [aizAmount, setAizAmount] = useState(0);
  const [paymentId, setPaymentId] = useState(null);
  const [loadingToastId, setLoadingToastId] = useState(null);

  const pollingIntervalRef = useRef(null);

  const { t: t1 } = useTranslation(NotificationI18n);
  const { t: t2 } = useTranslation(PageSettingsI18n);

  const handleChange = value => {
    setSelectedPayment(value);
  };
const handleInputValue = e => {
  const inputValue = e.target.value;

  if (inputValue.length < 2) {
    setValueAmount(inputValue);
    return;
  }

  const numericValue = Number(inputValue);
  if (!isNaN(numericValue) && numericValue >= 0) {
    setValueAmount(numericValue);
  } else {
    setValueAmount(0);
  }
};

  const handleBlur = () => {
    if (Number(valueAmount) < 10) {
      showErrorNotification("The minimum amount of credits is 10");
      setValueAmount(10);
    }
  };

  const user = useUser();
  const dataUser = user?.user_metadata;

  const fetchAIZTokenAmount = async () => {
    try {
      if (valueAmount <= 0) {
        return;
      }
      setIsLoading(true);
      const response = await getAmountOfAIZPerUsdc(valueAmount);
      setAizAmount(response.amountOfAIZPerUsdc);
    } catch (error) {
      console.error(t1("credits.failedAiz"), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAizSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification(t1("credits.enterValueAiz"));
      return;
    }

    try {
      const BASE_CHAIN_ID = BLOCKCHAINS.BASE.chainId;
      const currentNetwork = await web3Service.getCurrentNetwork();

      if (currentNetwork.chainId.toString() !== BASE_CHAIN_ID) {
        toast(t1("credits.onlyBaseNetwork"), {
          action: {
            label: "OK",
            onClick: () => web3Service.changeNetwork(BASE_CHAIN_ID),
          },
          className: "toast-no-creditis",
        });

        return;
      }

      setIsLoading(true);

      const receipt = await web3Service.transferToken("", aizAmount);

      const toastId = toast.loading(t1("credits.processingMetamask"));

      const confirmations = await receipt.wait();

      if (confirmations.status !== 1) {
        throw new Error("Transaction failed");
      }

      toast.loading(t1("credits.addCredits"), { id: toastId });

      await postPayment({
        amount: valueAmount,
        method: "token",
        transaction_id: receipt.hash,
      });

      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEYS.USER_CREDITS),
        queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS),
      ]);

      showSuccessNotification(t1("credits.addSuccessfully"), { id: toastId });

      setOpenModal(false);
    } catch (error) {
      if (error?.reason === "ERC20: transfer amount exceeds balance") {
        toast(t1("credits.aizInsufficient"), {
          action: {
            label: "Buy AIZ",
            onClick: () => {
              window.open(
                "https://app.uniswap.org/explore/tokens/base/0xc51d94a9936616b90e26abe61921ab3b4e66a149",
                "_blank"
              );
            },
          },
          className: "toast-no-creditis",
        });
        return;
      }

      if (error?.reason === "rejected") {
        return;
      }

      if (error?.message === "No MetaMask found!") {
        toast(t1("credits.installMetamask"), {
          className: "toast-no-creditis",
          action: {
            label: "Install MetaMask",
            onClick: () => {
              window.open("https://metamask.io/download.html", "_blank");
            },
          },
        });
        return;
      }

      showErrorNotification(
        error?.message || "Failed to process the AIZ token payment"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePixSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification(t1("credits.addValuePix"));
      return;
    }
    try {
      setIsLoading(true);

      const response = await postPayment({
        amount: valueAmount,
        method: "pix",
      });

      setPaymentId(response.id);

      queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS);

      const qrcodeBase64 = response.pix.qrcode;
      const pixCode = response.pix.txinfoemvqrcps;

      const qrcodeImage = qrcodeBase64?.includes("data:image")
        ? qrcodeBase64
        : `data:image/png;base64,${qrcodeBase64}`;

      setQRCodeImage(qrcodeImage);
      setPixCode(pixCode);
      setOpenModal(false);
      setModalPix(true);
    } catch (error) {
      console.error("Error during Pix payment:", error);
      showErrorNotification(
        error?.message || "Failed to process the Pix payment"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification(t1("credits.addValueCard"));
      return;
    }
    try {
      setIsLoading(true);

      const response = await postPayment({
        amount: valueAmount,
        method: "card",
      });

      queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS);

      if (response.payment_url) {
        window.location.href = response.payment_url;
      }
    } catch (error) {
      console.error("Error during card payment:", error);
      showErrorNotification(
        error?.message || "Failed to process the card payment"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleClick = () => {
    switch (selectedPayment) {
      case PAYMENT_METHODS.AIZ:
        handleAizSelection();
        break;
      case PAYMENT_METHODS.PIX:
        handlePixSelection();
        break;
      case PAYMENT_METHODS.CARD:
        handleCardSelection();
        break;
      default:
        showErrorNotification(t1("credits.selectMethod"));
    }
  };

  const startPollingPaymentStatus = () => {
    const toastId = t1("credits.awaitingPayment");
    setLoadingToastId(toastId);

    pollingIntervalRef.current = setInterval(async () => {
      try {
        const paymentStatusResponse = await getUserPaymentById(paymentId);

        if (paymentStatusResponse.status === "completed") {
          clearInterval(pollingIntervalRef.current);

          await Promise.all([
            queryClient.invalidateQueries(QUERY_KEYS.USER_CREDITS),
            queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS),
          ]);

          setModalPix(false);
          setQRCodeImage(null);
          setPixCode("");
          setPaymentId(null);
          setOpenModal(false);
          setPaymentId(null);

          showSuccessNotification(t1("credits.addSuccessfully"), {
            id: toastId,
          });
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        toast.dismiss(toastId);
      }
    }, 5000);
  };

  const copyCodePix = () => {
    navigator.clipboard.writeText(pixCode);
    showSuccessNotification(t1("credits.pixCodeCopied"));
  };

  useEffect(() => {
    if (selectedPayment === PAYMENT_METHODS.AIZ && valueAmount > 0) {
      fetchAIZTokenAmount();
    }
  }, [selectedPayment, valueAmount]);

  useEffect(() => {
    if (paymentId) {
      startPollingPaymentStatus();
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [paymentId]);

  const handleCloseModalPix = () => {
    setModalPix(false);
    if (loadingToastId) {
      toast.dismiss(loadingToastId);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("pay")) {
      setOpenModal(true);
    }
  }, []);

  return (
    <>
      <div className="plan-settings">
        <div className="w-100 d-flex align-items-center gap-2">
          {credits.isLoading ? (
            <h2>
              <IconLoader2 className="spin" width={16} height={16} />
            </h2>
          ) : (
            <h2>{formatPriceInUSD(Number(credits.amount || 0), 5)}</h2>
          )}
          <span>{t2("credits.availiable")}</span>
        </div>
        <div className="d-flex justify-content-end w-100">
          <ButtonType1
            text={t2("credits.buyCredits")}
            props={{
              disabled: false,
              style: { padding: "10px 16px", borderRadius: 10 },
              onClick: () => {
                setOpenModal(true);
              },
            }}
          />
          {qrcodeImage && (
            <ModalGlobal
              open={modalPix}
              showBtnClose={false}
              onClick={() => {
                handleCloseModalPix();
              }}
              style={{ width: "100%", maxWidth: 500 }}
              classNameChildren={
                "d-flex flex-column justify-content-between gap-5 modal-pix"
              }
            >
              <div className="d-flex flex-column gap-2">
                <div className="d-flex align-items-center gap-2">
                  <img src={imgPix} alt="" width={24}/>
                  <h4 className="m-0 font-size-20">{t2("credits.modalPix.title")}</h4>
                </div>
                <span>{t2("credits.modalPix.text")}</span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center gap-4">
                <img
                  src={qrcodeImage}
                  alt="QR Code"
                  style={{ width: "100%", maxWidth: 300 }}
                />
                <div className="d-flex flex-column align-items-center gap-2">
                  <div className="d-flex align-items-center gap-2">
                    <span className="one-line" style={{ width: "200px", color: COLORS.gray900 }}>
                      {t2("buttons.pix")} {pixCode}
                    </span>
                    <ButtonType1
                      text={<IconCopy stroke={1.5} size={16} />}
                      variation="secondary"
                      props={{
                        disabled: false,
                        style: { padding: "0 5px" },
                        onClick: copyCodePix,
                      }}
                    />
                  </div>
                  <div style={{ background: COLORS.gray100, padding: "5px 10px", borderRadius: 8 }}>{loadingToastId && <IconLoader2 className="spin" width={16} height={16} />} {loadingToastId}</div>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-end">
                <ButtonType1
                  text={t2("credits.modalPix.button")}
                  variation="secondary"
                  props={{
                    disabled: false,
                    className: "button-modal-pix",
                    style: { padding: "8px 25px", borderRadius: 8 },
                    onClick: handleCloseModalPix,
                  }}
                />
              </div>
            </ModalGlobal>
          )}
        </div>
      </div>

      <ModalPayment
        openModal={openModal}
        setOpenModal={setOpenModal}
        valueAmount={valueAmount}
        setValueAmount={setValueAmount}
        selectedPayment={selectedPayment}
        handleClick={handleClick}
        isOpenSelect={isOpenSelect}
        handleChange={handleChange}
        isLoading={isLoading}
        aizAmount={aizAmount}
        dataUser={dataUser}
        t2={t2}
        handleInputValue={handleInputValue}
        handleBlur={handleBlur}
      />
    </>
  );
}

export default PurchaseCredits;
