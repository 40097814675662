import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { ModalSelectAssistantsI18n } from "./ModalSelectAssistant.i18n";

const TabsModalAssistants = ({ aizzy, community, myAssistants }) => {
  const [activeTab, setActiveTab] = useState("aizzy");
  const { t } = useTranslation(ModalSelectAssistantsI18n);

  const tabs = [
    {
      id: "aizzy",
      label: t("tabs.aizzy"),
      content: aizzy,
    },
    {
      id: "commmunity",
      label: t("tabs.comminuty"),
      content: community,
    },
    {
      id: "myAssistants",
      label: t("tabs.my"),
      content: myAssistants,
    },
  ];

  return (
    <div>
      <div className="d-flex mb-4 gap-2">
        {tabs.map(tab => (
          <button
            type="button"
            key={tab.id}
            className={`tab-button one-line ${
              activeTab === tab.id ? "active" : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            <i className={tab.icon} />
            {tab.label}
          </button>
        ))}
      </div>
      {tabs.map(
        tab => tab.id === activeTab && <div key={tab.id}>{tab.content}</div>
      )}
    </div>
  );
};

export default TabsModalAssistants;
