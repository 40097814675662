/* eslint-disable array-callback-return */
import { Link, useNavigate } from "react-router-dom";
import { AizzyLogo } from "../../AizzyLogo";
import ButtonType1 from "../../Buttons/ButtonType1";
import COLORS from "../../../utils/constants/colors";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { IconMenu2 } from "@tabler/icons-react";
import { LandingPageI18n } from "./LandingPageTranslactions";
import useTranslation from "../../../hooks/useTranslation";
import MenuMobile from "./MenuMobile";
import { useState } from "react";

const HeaderLandingPage = () => {
  const state = useSelector(state => state);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const { t } = useTranslation(LandingPageI18n);

  const navigate = useNavigate();

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const arrayNav = [
    {
      title: "About",
      link: "",
    },
    {
      title: "Capabilities",
      link: "",
    },
    {
      title: "Docs",
      link: "",
    },
    {
      title: "FAQ",
      link: "",
    },
  ];

  return (
    <>
      <section
        className="d-flex justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: breakpointLg ? "100%" : "calc(100% - 142px)",
          height: "130px",
          zIndex: 100,
        }}
      >
        <nav
          className="w-100 d-flex justify-content-between align-items-center"
          style={{
            border: `1px solid ${COLORS.gray200}`,
            background: COLORS.white,
            borderRadius: 24,
            padding: "24px",
            maxWidth: breakpointLg ? "calc(100% - 32px)" : 1280,
          }}
        >
          <AizzyLogo
            width={breakpointLg ? 83 : 111}
            height={breakpointLg ? 24 : 32}
          />

          {!breakpointLg && (
            <div>
              <ul className="d-flex gap-3">
                {arrayNav?.map(item => {
                  return (
                    <li>
                      <Link style={{ color: COLORS.gray500 }} to={item.link}>
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="d-flex gap-2">
            <ButtonType1
              text={t("button")}
              props={{
                onClick: () => navigate("/"),
                desabled: false,
                style: {
                  borderRadius: "8px",
                  width: breakpointLg ? 110 : 127,
                  height: breakpointLg ? 32 : 40,
                },
              }}
            />
            {breakpointLg && (
              <ButtonType1
                props={{
                  disabled: false,
                  onClick: () => setOpenMenuMobile(!openMenuMobile),
                  style: {
                    borderRadius: "10px",
                    background: COLORS.gray100,
                    width: 32,
                    height: 32,
                  },
                }}
                variation="secondary"
                text={<IconMenu2 size={16} stroke={1.5} />}
              />
            )}
          </div>
        </nav>
      </section>
      {openMenuMobile ? (
        <MenuMobile openMenuMobile={() => setOpenMenuMobile(!openMenuMobile)} />
      ) : null}
    </>
  );
};

export default HeaderLandingPage;
