import { IconLink, IconRobotFace, IconTrendingUp } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import CardLandingPageType4 from "../../Cards/CardLandingPageType4";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import useTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";

const SectionForLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = useTranslation(LandingPageI18n);

  const breakpointLg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <section
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ padding: breakpointLg ? "80px 16px" : "180px", gap: breakpointLg ? 64 : 120 }}
    >
      <TitleLandingPage
        darkMode
        mediumFont
        gap={24}
        alignItems={"center"}
        textAlign={"center"}
        headerText={t("sectionFour.badge")}
        headerWidth={100}
        widthTitle={500}
        border={`1px solid ${COLORS.gray200}`}
        borderRadius={100}
        title={t("sectionFour.title")}
        description={t("sectionFour.description")}
        showButton={false}
      />
      <div className="w-100 d-flex flex-column flex-lg-row justify-content-between" style={{ gap: 40 }}>
        <CardLandingPageType4
          img={<IconRobotFace stroke={1.5} size={25} />}
          text="Promote our AI using your unique affiliate link and earn a 10% commission for each subscription from your referral link."
        />
        <CardLandingPageType4
          img={<IconLink stroke={1.5} size={25} />}
          text="Share your affiliate link on social media, blogs, or websites, and receive 10% of every subscription made via your link."
        />
        <CardLandingPageType4
          img={<IconTrendingUp stroke={1.5} size={25} />}
          text="Increase your earnings by generating more subscriptions through your link. You’ll earn a 10% commission on every sale made."
        />
      </div>
    </section>
  );
};

export default SectionForLandingPage;
