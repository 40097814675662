import { IconBolt, IconChevronDown, IconLoader2 } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";
import { UserSubscription } from "./UserSubscription";
import useUserCredits from "../../hooks/useUserCredits";
import { truncateNumber } from "../../utils/stringutils";
import { useUser } from "@supabase/auth-helpers-react";
import { Link, useNavigate } from "react-router-dom";
import SocialMideas from "../../utils/constants/SocialMedia";
import { useGetIsLogged } from "../../hooks/smallHooks";
import { useDispatch } from "react-redux";
import { setOpenLeftBar } from "../../redux/general/action";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";
import { useState } from "react";

const CardFooterSideLeftBar = ({ t }) => {
  const { credits, hasSubscriptionActive, isLoadingCredits } = useUserCredits();
  const user = useUser();
  const isSubscriptionActive = hasSubscriptionActive();
  const navigate = useNavigate();
  const logged = useGetIsLogged();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const avatarProfile = user?.user_metadata?.picture;

  return (
    <div className={"footer-aside-nav"}>
      <div
        className="d-flex flex-column gap-3"
        style={{
          color: COLORS.gray700,
          padding: 12,
          borderRadius: 12,
          backgroundColor: COLORS.gray100,
          gap: 12,
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={avatarProfile ? avatarProfile : imgProfile}
              alt=""
              style={{ borderRadius: "50%", width: 28, height: 28 }}
            />
            <span className="one-line font-size-14">
              {user?.email?.split("@")[0]}
            </span>
          </div>
          <IconChevronDown
            className="cursor-pointer"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
            stroke={1.5}
            size={16}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
        {isOpen && (
          <div
            className="d-flex align-items-center gap-2 cursor-pointer animate__animated animate__fadeIn"
            style={{ background: COLORS.white, padding: 8, borderRadius: 8 }}
            onClick={() =>
              logged
                ? navigate("/settings?tab=credits")
                : dispatch(setOpenLeftBar(true))
            }
          >
            {isSubscriptionActive ? (
              <div className="d-flex w-100 justify-content-center">
                <UserSubscription />
              </div>
            ) : (
              <>
                {isLoadingCredits ? (
                  <div className="w-100 d-flex align-items-center justify-content-center">
                    <IconLoader2 className="spin" stroke={1.5} size={16} />
                  </div>
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between gap-2 font-size-12">
                    <div className="d-flex align-items-center gap-2">
                      <IconBolt stroke={1.5} size={16} />
                      <span>{t("footer.credits")}</span>
                    </div>
                    <span className="d-flex align-items-center">
                      $
                      {(credits?.amount
                        ? truncateNumber(credits.amount, 2)
                        : 0
                      ).toFixed(2)}{" "}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="d-flex flex-column gap-2">
        <span className="font-size-12">{t("footer.help")}</span>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <span className="font-size-12" s>
            {t("footer.follow")}
          </span>
          <ul className="d-flex gap-2">
            {SocialMideas.map((social, index) => (
              <li key={index}>
                <Link to={social.link} target="_blank">
                  <img src={social.image} alt={social.name} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardFooterSideLeftBar;
