/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd";
import IconArrowDown from "../../../assets/images/icons-new-layout/arrow-down.svg";
import { IconAlertCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { updateAssistantData } from "../../../redux/createAssistants/action";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../Add-ons/Tooltip";
import useTranslation from "../../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistants.i18n";

const CreateAssistantData = () => {
  const dispatch = useDispatch();
  const assistantData = useSelector(
    state => state.assistantReducer.assistantData
  );

  const { t } = useTranslation(ModalCreateAssistantsI18n);

  const [prompt, setPrompt] = useState(assistantData?.prompt || "");
  const [setDefaultGreetings] = useState(
    assistantData?.defaultGreetings || ""
  );
  const [language, setLanguage] = useState(assistantData?.language || "");

  useEffect(() => {
    setPrompt(assistantData?.prompt || "");
    setDefaultGreetings(assistantData?.defaultGreetings || "");
    setLanguage(assistantData?.language || "");
  }, [assistantData]);

  const handlePromptChange = e => {
    const value = e.target.value;
    setPrompt(value);
    dispatch(updateAssistantData({ prompt: value }));
  };

  const handleLanguageChange = value => {
    setLanguage(value);
    dispatch(updateAssistantData({ language: value }));
  };

  return (
    <div className="create-assistants-data">
      <div className="d-flex w-100 justify-content-between align-items-start">
        <div className="position-relative h-100 d-flex tolltip-about-create-assistant">
          <span className="span">{t("tabs.data.inputs.prompt.label")}</span>
          <Tooltip text={t("tabs.data.inputs.prompt.tooltip")}>
            <IconAlertCircle
              className="position-absolute"
              cursor={"pointer"}
              stroke={1.5}
              size={16}
              style={{ top: "-5px", right: "-20px" }}
            />
          </Tooltip>
        </div>
        <div className="textarea-data">
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            placeholder={t("tabs.data.inputs.prompt.placeholder")}
          />
        </div>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.data.inputs.language.label")}</span>
        <Select
          className="select-antd-create-assistant"
          suffixIcon={
            <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
          }
          size="large"
          showSearch
          filterOption={false}
          value={language === "" ? "Select language" : language}
          onChange={handleLanguageChange}
          placeholder="Select language"
        >
          <Select.Option value="English">English</Select.Option>
          <Select.Option value="Spanish">Spanish</Select.Option>
          <Select.Option value="Português">Português</Select.Option>
        </Select>
      </div>
    </div>
  );
};

export default CreateAssistantData;
