import { useDispatch, useSelector } from "react-redux";
import {
  setConfigsMainChat,
  setOpenLeftBarMobile,
} from "../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import { IconLoader2, IconMessages, IconTrash } from "@tabler/icons-react";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAsideType1 from "../../../Buttons/ButtonAsideType1";
import COLORS from "../../../../utils/constants/colors";
import { useChatStore } from "../../../../state/chat";
import { deleteChannel } from "../../../../api";
import { useState } from "react";
import { QUERY_KEYS, queryClient } from "../../../../libs/react-query";
import useTranslation from "../../../../hooks/useTranslation";
import { ChatMenuPopUpI18n } from "../Chats/ChatMenuPopUp.i18n";

const GroupListChats = ({ title = "Title", chats = [] }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [loadingChatId, setLoadingChatId] = useState(null);
  const [iconTrash, setIconTrash] = useState(null);
  const { reset } = useChatStore();
  const navigate = useNavigate();
  const location = useLocation();
  const configsMainChat = useGetConfigsMainChat();
  const sizeIcon = state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG ? 21 : 18;
  const { t } = useTranslation(ChatMenuPopUpI18n);
  function handleSelectChat(chat) {
    if (configsMainChat?.chatData?._id === chat._id) return;
    dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, chat));
    reset();
  }

  const handleChannelDelete = async (chatId) => {
    setLoadingChatId(chatId);
    const confirmed = window.confirm(t("notifications.delete.confirm"));
    if (confirmed) {
      try {
        await deleteChannel(chatId);
        queryClient.invalidateQueries(QUERY_KEYS.CHANNELS);
      } catch (error) {
        console.error("Error deleting channel:", error);
      } finally {
        setLoadingChatId(null);
      }
    } else {
      setLoadingChatId(null);
    }
  };
  

  const isMobile = window.matchMedia("(max-width: 991.92px)").matches;

  return (
    <section className="group-list-chats">
      {title && <h6>{title}</h6>}
      <ul>
        {chats.map((chat, index) => (
          <li key={index}
            onMouseEnter={() => setIconTrash(chat._id)}
            onMouseLeave={() => setIconTrash(null)}
          >
            <ButtonAsideType1
              onClick={() => {
                handleSelectChat(chat);
                if (location.pathname !== "/") {
                  navigate("/");
                }
                isMobile && dispatch(setOpenLeftBarMobile(false));
              }}
              hover
              props={{
                className: "hover-list-chats",
                style: {
                  justifyContent: loadingChatId === chat._id ? "center" : "flex-start",
                  width: "100%",
                  backgroundColor: configsMainChat?.chatData?._id === chat._id ? COLORS.gray100 : "",
                  color: COLORS.gray900,
                },
              }}
            >
              {!state.configsGeneralReducer.openLeftBar && (
                <IconMessages size={sizeIcon} style={{ minWidth: sizeIcon }} />
              )}
              {state?.configsGeneralReducer?.openLeftBar &&
                (loadingChatId === chat._id ? (
                  <IconLoader2 className="spin" />
                ) : (
                  <div className="font-size-14 align-items-center w-100 d-flex justify-content-between">
                    <span className="one-line">{chat?.channelName}</span>
                    {iconTrash === chat._id && (
                      <span>
                        <IconTrash
                          stroke={1.5}
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChannelDelete(chat._id && chat._id);
                          }}
                        />
                      </span>
                    )}
                  </div>
                ))}
            </ButtonAsideType1>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default GroupListChats;
