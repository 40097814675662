import { useSelector } from "react-redux";
import logoAizzy from "../../../assets/images/icons-new-layout/logoFooter.svg";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { Link } from "react-router-dom";
import SocialMideas from "../../../utils/constants/SocialMedia";
import COLORS from "../../../utils/constants/colors";
import { LandingPageI18n } from "./LandingPageTranslactions";
import useTranslation from "../../../hooks/useTranslation";

const FooterLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = useTranslation(LandingPageI18n);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <section
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{ padding: breakpointLg ? "80px 16px" : "80px", gap: 80 }}
    >
      <div
        className="d-flex justify-content-between"
        style={{
          width: "100%",
          flexDirection: breakpointLg ? "column" : "row",
          gap: breakpointLg ? 40 : 0,
        }}
      >
        <div>
          <h5 className="font-size-14">
            {t("footer.description")}
          </h5>
        </div>
        <div
          className="d-grid footer-landing-page"
          style={{
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: breakpointLg ? 50 : 80,
            fontSize: breakpointLg ? 12 : 14,
          }}
        >
          <ul className="d-flex flex-column gap-lg-4 gap-3">
            <li>
              <span>{t("footer.explore.title")}</span>
            </li>
            <li>
              <Link>{t("footer.explore.github")}</Link>
            </li>
            <li>
              <Link>{t("footer.explore.aiz")}</Link>
            </li>
            <li>
              <Link>{t("footer.explore.base")}</Link>
            </li>
          </ul>
          <ul className="d-flex flex-column gap-4">
            <li>
              <span>{t("footer.learnMore.title")}</span>
            </li>
            <li>
              <Link>{t("footer.learnMore.about")}</Link>
            </li>
            <li>
              <Link>{t("footer.learnMore.docs")}</Link>
            </li>
            <li>
              <Link>{t("footer.learnMore.faqs")}</Link>
            </li>
          </ul>
          <ul className="d-flex flex-column gap-4">
            <li>
              <span>{t("footer.legal")}</span>
            </li>
            <li>
              <Link>{t("footer.disclaimer")}</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-between" style={{ width: "100%" }}>
        <span className="font-size-12" style={{ color: COLORS.gray400, marginRight: 16 }}>© 2024 Aizzy. All rights reserved.</span>
        <ul className="d-flex gap-2">
          {SocialMideas.map((social, index) => (
            <li key={index}>
              <Link
                to={social.link}
                target="_blank"
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 6,
                  background: COLORS.gray100,
                }}
              >
                <img src={social.image} alt={social.name} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {breakpointLg ? (
        ""
      ) : (
        <div className="w-100 position-relative" style={{ height: 190 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
            }}
          >
            <img src={logoAizzy} alt="" style={{ width: "100%" }} />
          </div>
        </div>
      )}
    </section>
  );
};

export default FooterLandingPage;
