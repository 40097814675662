import {
  IconChevronsRight,
  IconDatabase,
  IconRobotFace,
  IconShield,
  IconSquare4,
} from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import CardLandingPageType1 from "../../Cards/CardLandingPageType1";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import useTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";

const SectionTwoLandingPage = () => {
  const state = useSelector(state => state);
  const { t } = useTranslation(LandingPageI18n);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const arrayCards = [
    {
      img: <IconRobotFace stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.robot"),
      top: breakpointLg ? 344 : 90,
      right: breakpointLg ? -10 : -126,
      id: 1
    },
    {
      img: <IconChevronsRight stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.chevron"),
      bottom: 127,
      top: 416,
      left: 880,
      right: -150,
      id: 2
    },
    {
      img: <IconShield stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.shield"),
      bottom: breakpointLg ? 97 : 55,
      left: 158,
      top: breakpointLg ? 344 : 'unset',
      right: 650,
      id: 3
    },
    {
      img: <IconDatabase stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.datas"),
      top: breakpointLg ? 344 : -40,
      right: breakpointLg ? 321 : 338,
      id: 4
    },
    {
      img: <IconSquare4 stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.tecnology"),
      top: breakpointLg ? 416 : 92,
      left: breakpointLg ? -190 : -110,
      right: 180,
      id: 5
    },
  ];

  return (
    <section className="d-flex justify-content-center" style={{ padding: breakpointLg ? "64px 16px 16px 16px" : "120px 72px" }}>
      <div
        className="h-100 d-flex justify-content-center position-relative overflow-hidden"
        style={{
          background: COLORS.gray100,
          minHeight: breakpointLg ? 512 : 580,
          borderRadius: 48,
          alignItems: breakpointLg ? "flex-start" : "center",
          width: "100%",
        }}
      >
        {breakpointLg ? (
          ""
        ) : (
          <>
            <div className="elipse" style={{ width: 600, height: 600 }}></div>
            <div className="elipse" style={{ width: 800, height: 800 }}></div>
            <div className="elipse" style={{ width: 1000, height: 1000 }}></div>
            <div className="elipse" style={{ width: 1200, height: 1200 }}></div>
          </>
        )}
        <TitleLandingPage
          styleMain={{
            marginTop: breakpointLg ? 40 : 0
          }}
          darkMode
          mediumFont
          gap={24}
          alignItems={"center"}
          textAlign={"center"}
          headerText={t("sectionTwo.badge")}
          headerWidth={67}
          widthTitle={500}
          border={`1px solid ${COLORS.gray200}`}
          borderRadius={100}
          title={t("sectionTwo.title")}
          description={t("sectionTwo.description")}
          showButton={true}
          buttonPadding={"8px 30px"}
        />
        {breakpointLg ?
          arrayCards.map((item, index) => (
            <CardLandingPageType1
              key={index}
              img={item.img}
              text={item.text}
              right={item.right}
              top={item.top}
            />
          ))
          : arrayCards.map((item, index) => (
            <CardLandingPageType1
              key={index}
              img={item.img}
              text={item.text}
              bottom={item.bottom}
              right={item.right}
              left={item.left}
              top={item.top}
            />
          ))}
      </div>
    </section>
  );
};

export default SectionTwoLandingPage;
