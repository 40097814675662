import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import GroupLogin from "../../components/Login/GroupLogin";
import settings from "./settings";
import useTranslation from "../../hooks/useTranslation";
import { PageSettingsI18n } from "../../components/Pages/SettingsNewLayout/PageSettings.i18n";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const Settings = () => {
  const state = useSelector(state => state);
  const [selectedTab, setSelectedTab] = useState(0);

  const { openLeftBar, openLogin } = state?.configsGeneralReducer || {};

  const { t } = useTranslation(PageSettingsI18n);

  const handleSettingClick = index => {
    setSelectedTab(index);
    localStorage.setItem("selectedTab", index);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let foundTabIndex;

    switch (true) {
      case urlParams.get("success") === "true":
        foundTabIndex = settings.findIndex(setting => setting.id === "credits");
        break;
      case urlParams.get("tab") !== null:
        foundTabIndex = settings.findIndex(
          setting => setting.id === urlParams.get("tab")
        );
        break;
      default:
        const savedTab = localStorage.getItem("selectedTab");
        if (savedTab !== null) {
          setSelectedTab(parseInt(savedTab, 10));
        }
    }

    if (foundTabIndex !== undefined) {
      setSelectedTab(foundTabIndex);
      localStorage.setItem("selectedTab", foundTabIndex);
    }
  }, []);

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.MD;
  const isMobileLg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;
  const isMobileXl = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.XL;

  return (
    <section className={`page-settings ${!openLeftBar ? "maximize" : ""}`}>
      <MenuTopBar publishAssistant={false} />
      <div className="d-flex align-items-md-center flex-column w-100" 
      style={{
        padding: isMobileLg ? "32px 0" : isMobileXl ? "40px 50px" : "40px 110px",
      }}>
        <div
          className="d-flex w-100 justify-content-md-center container-page-settings"
          style={{ gap: isMobile ? 50 : 80 }}
        >
          <div
            className="d-flex flex-column gap-2"
            style={{
              width: "100%",
              minWidth: isMobile ? "100%" : 220,
              maxWidth: isMobile ? "100%" : 220,
              flexShrink: 0,
            }}
          >
            <Swiper
              direction={isMobile ? "horizontal" : "vertical"}
              className=" w-100 m-0 d-flex overflow-visible"
              spaceBetween={8}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                992: {
                  slidesPerView: 5,
                },
              }}
            >
              {settings?.map((setting, index) => (
                <SwiperSlide
                  key={setting.id}
                  className={`btn-setting-tab w-auto d-flex flex-column gap-2 setting-active cursor-pointer justify-content-center
                  ${selectedTab === index ? "active" : ""}`}
                  onClick={() => handleSettingClick(index)}
                  style={{ maxHeight: 40 }}
                >
                  <div className="d-flex gap-3 align-items-center" style={{ minHeight: 40, padding: "0 16px"}}>
                    <div className="d-flex align-items-center">
                      {setting.icon}
                    </div>
                    {`${t(setting.label)}`}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="w-100" style={{ maxWidth: 632, overflow: "auto" }}>
            {settings[selectedTab].content}
          </div>
        </div>
      </div>
      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default Settings;
